import { v4 as uuidv4 } from 'uuid';
import { axiosClient } from '../../utils/axiosClient';
import { makeApiRequest } from '../../utils/makeApiRequest';

const RANDOM_ID = 'random-user-id';
const randomId = window.localStorage.getItem(RANDOM_ID);
const uuid = randomId || uuidv4();
if (!randomId) {
  window.localStorage.setItem(RANDOM_ID, uuid);
}

export type ENABLED_FEATURES_KEY =
  | 'oga-clevertap'
  | 'giving_rememberlastlogin_oga'
  | 'hide-giving-history'
  | 'show-new-oga'
  | 'giving_oga_ddl_autologin_and'
  | 'giving_oga_ddl_autologin_ios'
  | 'oga-fullstory';

export interface EnabledFeaturesResponse {
  allFeatures: {
    label: ENABLED_FEATURES_KEY;
    isEnabled: boolean;
  }[];
  enabledFeatures: {
    label: ENABLED_FEATURES_KEY;
    isEnabled: boolean;
  }[];
  meta: {
    authenticated: boolean;
  };
}

const getEnabledFeaturesUrl = '/enabled-features';
export const getEnabledFeatures = () => {
  const httpRequest = axiosClient.get(getEnabledFeaturesUrl, {
    headers: {
      user_id: uuid,
      unauthenticated_id: uuid,
      'user-id': uuid,
      'unauthenticated-id ': uuid
    }
  });
  return makeApiRequest<EnabledFeaturesResponse>(httpRequest);
};
