import React from 'react';
import styled from 'styled-components';
import colors from '../../../../theme/colors';
import GivelifyBottomSheet from '../../common/Modal/GivelifyBottomSheet';
import { Icon } from '../../common';
import { Text } from '../../common/Text/Text';
import { GivelifyButton } from '../../common/Button/GivelifyButton';

interface DeepLinkCopiedModalProps {
  open: boolean;
  title: string | JSX.Element;
  onClick: () => unknown;
}

const StyledDiv = styled.div`
  text-align: center;
  .deep-link-heading,
  .deep-link-subheading {
    max-width: 295px;
    margin-left: auto;
    margin-right: auto;
  }
  .deep-link-heading {
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    .deep-link-copied-text {
      color: ${colors.primaryGuidingViolet};
    }
  }
  .deep-link-subheading {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 40px;
  }
  .deep-link-hand {
    width: 100%;
    max-width: 100% !important;
    max-height: 300px !important;
    height: auto;
  }
`;

const DeepLinkCopiedModal: React.FC<DeepLinkCopiedModalProps> = ({
  open,
  title,
  onClick
}) => {
  if (!open) return null;
  return (
    <GivelifyBottomSheet>
      <StyledDiv>
        <Text
          variant="large"
          className="deep-link-heading"
        >
          {title}
        </Text>
        <Text
          color="primarySecondaryContent"
          className="deep-link-subheading"
        >
          This lets you pick up where you
          <br />
          left off in the app
        </Text>
        <Icon
          name="deep-link-hand"
          className="deep-link-hand"
        />
        <GivelifyButton
          id="deep-link-hand-button"
          onClick={onClick}
        >
          Continue to App Store
        </GivelifyButton>
      </StyledDiv>
    </GivelifyBottomSheet>
  );
};

export default DeepLinkCopiedModal;
