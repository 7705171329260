export const emailPattern =
  /^[áéíóúüñÁÉÍÓÚÜÑa-zA-Z\d._%\-+]{1,256}@[a-zA-Z\d][a-zA-Z\d-]{0,64}(\.[a-zA-Z\d][a-zA-Z\d-]{0,25})+$/;

export const passwordPattern =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])(?=.*[A-Z0-9])(?=.*[a-z0-9])/;

export const phonePattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

const defaultFormat = /(\d{1,4})/g;
const default14Format = /(\d{1,4})(\d{1,6})(\d{1,4})/;

export interface CardThumb {
  type: string;
  pattern: RegExp;
  format: RegExp;
  length: [number];
  cvcLength: [number];
  luhn: boolean;
}

export const cards: CardThumb[] = [
  {
    type: 'discover',
    pattern: /^(6011|65|64[4-9]|622)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'mastercard',
    pattern: /^5[1-5]/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'visa',
    pattern: /^4/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },
  {
    type: 'american express',
    pattern: /^3[47]/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [4],
    luhn: true
  },
  {
    type: 'diners club',
    pattern: /^(30[0-5]|309|36|38|39)/,
    format: default14Format,
    length: [14],
    cvcLength: [3],
    luhn: true
  }
];

export const memberIdPattern = /^[a-zA-Z0-9_-]+$/;
