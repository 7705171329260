import React from 'react';
import MaskedInput from 'react-text-mask';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import { FormItem, SelectInput } from './styles';
import './style.css';
import { CAN, countries as allCountries, USA } from '../DonorProfile/constants';
import DonorAddPaymentMethodView from './DonorAddPaymentMethodView';
import { Text } from '../../components/Donee/components/common/Text/Text';
import { useDonor } from '../../providers/DonorProvider/useDonor';
import { webConfig } from '../../utils/webConfig';
import {
  CardThumb,
  cards
} from '../../components/Donee/components/common/constants';
import Label from '../../components/Donee/components/common/Label';
import TextInput from '../../components/Donee/components/common/Input/TextInput';
import { zipCodeMask } from '../../components/LoginModal/utils';

function CheckCVV(validCVV: any, cardTypeData: any) {
  if (!validCVV) {
    if (cardTypeData !== null) {
      return (
        <Text
          color={'red'}
          variant={'small'}
        >
          CVV must have {cardTypeData.cvcLength} number
        </Text>
      );
    }
    return (
      <Text
        color={'red'}
        variant={'small'}
      >
        CVV must have 3 number
      </Text>
    );
  }
  return '';
}

function DonorAddPaymentMethod(props: any) {
  const { donor } = useDonor() || {};
  const donorId = donor?.id;
  let donorCountry = donor?.phone.country.name;
  if (!donorCountry || ['', 'US'].includes(donorCountry.toUpperCase())) {
    donorCountry = 'USA';
  }

  const [name, setName] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [expiration, setExpiration] = React.useState('');
  const [cvv, setCvv] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [country, setCountry] = React.useState(donorCountry);
  const [zip, setZip] = React.useState('');
  const [paymentType, setPaymentType] = React.useState('card');
  const [captchaResponse, setCaptchaResponse] = React.useState('');
  const [captchaError, setCaptchaError] = React.useState('');
  const [cardThumb, setCardThumb] = React.useState('');
  const [cardError, setCardError] = React.useState('');
  const [oldZipcode, setOldZipcode] = React.useState('');
  const [CVVInfoPopup, setCvvInfoPopup] = React.useState(false);
  const [cardTypeData, setCardTypeData] = React.useState<
    CardThumb | '' | undefined | null
  >('');
  const [isZipcodeChange, setIsZipcodeChanged] = React.useState(false);
  const [isCardNumberValidated, setIsCardNumberValidated] =
    React.useState(false);
  const [error, setError] = React.useState();

  const push = useNavigate();
  const { doneeId } = useParams();

  const {
    methods,
    newCardData,
    error: errorProp,
    errorCode: errorCodeProp,
    onNewCardPopupClose,
    type: propType,
    buttonLabel,
    onGetNewCardData
  } = props;

  React.useEffect(() => {
    if (typeof methods !== 'undefined') {
      if (newCardData !== null) {
        let creditNumber = newCardData.credit_number;
        creditNumber = creditNumber.replace(/(.{4})/g, '$1 ');
        let newExpiration = newCardData.credit_expiration;
        newExpiration = newExpiration.replace(/(.{2})/g, '$1/');
        newExpiration = newExpiration.substr(0, newExpiration.length - 1);
        const newCardTypeData = checkCardType(newCardData.credit_number);

        setName(newCardData.billing_name);
        setNumber(creditNumber);
        setExpiration(newExpiration);
        setCvv(newCardData.credit_cvv);
        setAddress(newCardData.billing_address);
        setCity(newCardData.billing_city);
        setState(newCardData.billing_state);
        setZip(newCardData.billing_zip);
        setCardThumb(newCardData.credit_type);
        setCardTypeData(newCardTypeData);
      } else if (donor) {
        setName(donor.name);
        setAddress(donor.address.street);
        setCity(donor.address.city);
        setState(donor.address.state);
        setZip(donor.address.zip);
      }
    }
  }, []);

  const onSetNumber = (newCardNumber: any) => {
    setNumber(newCardNumber);
    let cardNumber = newCardNumber;
    cardNumber = cardNumber.replace(/\s+/g, '');
    const type = checkCardType(cardNumber);
    const cardLength = cardNumber.replace(/_/g, '').toString().length;

    setIsCardNumberValidated(false);
    if (typeof type !== 'undefined') {
      setCardTypeData(type);
      setCvv('');
      setCardThumb(type.type);
      setCardError('');
      if (type.length.indexOf(cardLength) === -1) {
        setCardError('Card number is not valid.');
        return false;
      }
    } else {
      setCardTypeData(null);
      setCvv('');
      setCardThumb('');
      setCardError('Card number type is not valid.');
      const cardNumber2 = newCardNumber;
      if (cardNumber2 === '') {
        setCardError('');
      }
      return false;
    }
    setIsCardNumberValidated(true);
    return true;
  };

  const onSetExpiration = (newExpiration: any) => {
    setExpiration(newExpiration);
    const expirationNumber = newExpiration;
    const expirationLength = expirationNumber.toString().length;

    if (expirationLength === 1) {
      if (expirationNumber > 1) {
        setExpiration(`0${expirationNumber}`);
      }
    }
  };

  const onSetZip = (newZip: any) => {
    setZip(newZip);
    setIsZipcodeChanged(true);

    if (country === USA) {
      let zipCode = newZip;
      zipCode = zipCode.replace('_', '');
      if (zipCode !== '') {
        if (zipCode.length === 5) {
          if (oldZipcode !== zipCode) {
            setOldZipcode(zipCode);

            const headers = {
              headers: {
                'Content-Type': 'application/json; charset=UTF-8'
              }
            };

            let param = '';
            param = `${param}address=${zipCode}`;
            param += '&sensor=false';
            param = `${param}&key=${webConfig.googlePlaceApiKey}`;

            Axios.get(
              `https://maps.google.com/maps/api/geocode/json?${param}`,
              headers
            )
              .then((response) => {
                if (response.data.status === 'OK') {
                  const results = response.data.results[0];
                  const addressComponents = results.address_components;
                  let newCity = '';
                  let stateCode = '';
                  addressComponents.forEach((value: any) => {
                    if (value.types.includes('locality')) {
                      newCity = value.long_name;
                    }
                    if (value.types.includes('administrative_area_level_1')) {
                      stateCode = value.short_name;
                    }
                  });

                  setCity(newCity);
                  setState(stateCode);
                }
              })
              .catch(() => null);
          }
        }
      }
    }
    return true;
  };

  const moveCaretAtEnd = (e: any) => {
    const tempValue = e.target.value;
    e.target.value = '';
    e.target.value = tempValue;
  };

  const resetRecaptcha = () => {
    setCaptchaResponse('');
    setCardError('');
  };

  const verifyCallback = (recaptchaToken: any) => {
    // Here you will get the final recaptchaToken!!!
    setCaptchaResponse(recaptchaToken);
    setCardError('');
  };

  const addNewCard = (dataInfo: any) => {
    if (captchaResponse === '' && !webConfig.disableCaptcha) {
      setCaptchaError('Please tick the checkbox to proceed');
      return false;
    }
    setCaptchaError('');

    const cardInfo = {
      name: decodeURIComponent(dataInfo.name),
      number: dataInfo.number.replace(/\s+/g, ''),
      expiration: dataInfo.expiration.replace('/', ''),
      cvv: dataInfo.cvv,
      type: ''
    };
    const type = checkCardType(cardInfo.number);
    if (typeof type === 'undefined') {
      return false;
    }
    setCardError('');

    cardInfo.type = type.type;

    let countryCode = USA;
    if (showCountry()) {
      countryCode = decodeURIComponent(dataInfo.country);
    }

    const billingInfo = {
      address: decodeURIComponent(dataInfo.address),
      city: decodeURIComponent(dataInfo.city),
      state: decodeURIComponent(dataInfo.state),
      country_code: countryCode,
      zip: dataInfo.zip
    };
    const data = {
      id: 'addDonorWallet',
      data: {
        donor_id: donorId,
        card_info: cardInfo,
        billing_info: billingInfo,
        captchaResponse
      }
    };

    if (propType === 'checkout') {
      const walletsData: any = {};
      walletsData.is_new = true;
      walletsData.credit_number = dataInfo.number.replace(/\s+/g, '');
      walletsData.credit_expiration = dataInfo.expiration.replace('/', '');
      walletsData.credit_cvv = dataInfo.cvv;
      walletsData.credit_type = type.type;
      walletsData.billing_name = dataInfo.name;
      walletsData.billing_address = dataInfo.address;
      walletsData.billing_city = dataInfo.city;
      walletsData.billing_state = dataInfo.state;
      walletsData.billing_zip = dataInfo.zip;
      walletsData.billing_country = countryCode;
      onGetNewCardData(walletsData, captchaResponse);
      return false;
    }

    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    if (!webConfig.baseAPIUrl) return;
    Axios.post(webConfig.baseAPIUrl, data, headers)
      .then((response) => {
        if (response.data.Response.Result.errorCode) {
          setError(response.data.Response.Result.errorMessage);
        } else {
          push(`/${doneeId}/wallet`, { state: '' });
        }
      })
      .catch(() => null);
    return undefined;
  };

  const checkCardType = (newNumber: any) => {
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      if (card.pattern.test(newNumber)) {
        return card;
      }
    }
    return undefined;
  };

  const validateCardExpiry = (newExpiration: any) => {
    let prefix;
    let month: any;
    let year: any;

    if (newExpiration.length === 5) {
      month = parseInt(newExpiration.toString().substr(0, 2), 10);
      year = parseInt(newExpiration.toString().substr(3, 2), 10);
    } else if (newExpiration.length === 4) {
      month = parseInt(newExpiration.toString().substr(0, 2), 10);
      year = parseInt(newExpiration.toString().substr(2, 2), 10);
    }

    if (year.toString().length === 2) {
      prefix = new Date().getFullYear();
      prefix = prefix.toString().slice(0, 2);
      year = prefix + year;
    }
    if (month > 12) {
      return false;
    }
    const expiry = new Date(parseInt(year, 10), month, 1);
    const currentTime = new Date();
    expiry.setMonth(expiry.getMonth() - 1);
    expiry.setMonth(expiry.getMonth() + 1, 1);

    return expiry >= currentTime;
  };

  const showInternationalInput = (newCountry: any) =>
    newCountry !== 'USA' && showCountry();

  const showState = (newState: any, newStates: any, newCountry: any) => {
    if (showInternationalInput(newCountry)) {
      return (
        <FormItem>
          <Label
            size={'small'}
            color={'primarySecondaryContent'}
            weight={undefined}
            value={undefined}
          >
            State/Province
          </Label>
          <TextInput
            value={decodeURIComponent(newState)}
            onChange={(e) => setState(e.target.value)}
            maxLength={55}
          />
        </FormItem>
      );
    }

    return (
      <FormItem>
        <Label
          size={'small'}
          color={'primarySecondaryContent'}
          weight={undefined}
          value={undefined}
        >
          State
        </Label>
        <SelectInput
          value={decodeURIComponent(newState)}
          onChange={(e) => setState(e.target.value)}
          className={newState === '' ? 'default' : ''}
        >
          <option value={''}>Select a state...</option>
          {newStates &&
            newStates.map((t: any) => (
              <option
                value={t.value}
                key={t.value}
              >
                {t.name}
              </option>
            ))}
        </SelectInput>
      </FormItem>
    );
  };

  const showZipCode = (newZip: any, newCountry: any) => {
    if (showInternationalInput(newCountry)) {
      return (
        <FormItem>
          <Label
            size={'small'}
            color={'primarySecondaryContent'}
            weight={undefined}
            value={undefined}
          >
            ZIP/Postal code
          </Label>
          <TextInput
            value={decodeURIComponent(newZip)}
            onChange={(e) => onSetZip(e.target.value)}
            maxLength={7}
          />
        </FormItem>
      );
    }

    return (
      <FormItem>
        <Label
          size={'small'}
          color={'primarySecondaryContent'}
          weight={undefined}
          value={undefined}
        >
          ZIP code
        </Label>
        <MaskedInput
          mask={zipCodeMask}
          className={'formInput'}
          value={newZip}
          onChange={(e) => onSetZip(e.target.value)}
        />
      </FormItem>
    );
  };

  const showCountry = () =>
    webConfig.doneeWithInternationalBillingAddressOption === doneeId ||
    webConfig.doneeWithInternationalBillingAddressOption === 'all';

  let zipInvalid: boolean = false;
  if (country === USA) {
    zipInvalid =
      Boolean(zip) &&
      zip.replace(/_/g, '').length > 0 &&
      zip.replace(/_/g, '').length < 5;
  } else if (country === CAN) {
    const canadianZipCodeRegEx =
      /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;
    zipInvalid = !canadianZipCodeRegEx.test(zip) || zip.length > 7;
  }

  // check valid expiration date
  let validExpire = true;
  if (
    expiration &&
    expiration.replace(/_/g, '').replace('/', '').length === 4
  ) {
    validExpire = validateCardExpiry(expiration);

    if (expiration.replace(/_/g, '').replace('/', '').substr(0, 2) === '00') {
      validExpire = false;
    }
  } else if (
    expiration.replace(/_/g, '').replace('/', '').length >= 1 &&
    expiration.replace(/_/g, '').replace('/', '').length < 4
  ) {
    validExpire = false;
  }

  // check valid CVV number
  let validCVV = true;
  if (
    cvv &&
    ((cardThumb !== 'american express' && cvv.replace(/_/g, '').length !== 3) ||
      (cvv &&
        cardThumb === 'american express' &&
        cvv.replace(/_/g, '').length !== 4))
  ) {
    validCVV = false;
  }

  let cardDetailDone = true;
  if (
    !number ||
    !expiration ||
    !cvv ||
    !name ||
    cardError !== '' ||
    !validExpire ||
    !validCVV
  ) {
    cardDetailDone = false;
  }

  let invisible = false;

  if (
    !number ||
    !expiration ||
    !cvv ||
    !name ||
    !address ||
    !city ||
    !state ||
    !zip ||
    zipInvalid
  ) {
    invisible = true;
  }

  // allow null/empty zip code for Countries that are not USA or Canada
  if (country && ![USA, CAN].includes(country) && !zip) {
    invisible = false;
  }

  return (
    <DonorAddPaymentMethodView
      name={name}
      number={number}
      expiration={expiration}
      cvv={cvv}
      address={address}
      city={city}
      state={state}
      country={country}
      zip={zip}
      paymentType={paymentType}
      cardError={cardError}
      CVVInfoPopup={CVVInfoPopup}
      error={error}
      isZipCodeChanged={isZipcodeChange}
      captchaError={captchaError}
      cardThumb={cardThumb}
      cardTypeData={cardTypeData}
      errorProp={errorProp}
      errorCodeProp={errorCodeProp}
      onNewCardPopupClose={onNewCardPopupClose}
      type={propType}
      buttonLabel={buttonLabel}
      setPaymentType={setPaymentType}
      setCvvInfoPopup={setCvvInfoPopup}
      setName={setName}
      setNumber={onSetNumber}
      setExpiration={onSetExpiration}
      setCvv={setCvv}
      setCountry={setCountry}
      setAddress={setAddress}
      setCity={setCity}
      verifyCallback={verifyCallback}
      resetRecaptcha={resetRecaptcha}
      addNewCard={addNewCard}
      showState={showState}
      moveCaretAtEnd={moveCaretAtEnd}
      showCountry={showCountry}
      showZipCode={showZipCode}
      invisible={invisible}
      cardDetailDone={cardDetailDone}
      allCountries={allCountries}
      validCVV={validCVV}
      checkCVV={CheckCVV}
      validExpire={validExpire}
      zipInvalid={zipInvalid}
      isCardNumberValidated={isCardNumberValidated}
    />
  );
}

export default DonorAddPaymentMethod;
