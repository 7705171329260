import toSnakeCase from 'decamelize-keys';
import { useCallback, useState } from 'react';
import { Donee } from '../providers/DoneeProvider';
import { axiosClient } from '../utils/axiosClient';
import { useGivingApiRequest } from './useGivingApiRequest';
import { makeApiRequest } from '../utils/makeApiRequest';
import { DonorWithWallet } from '../providers/DonorProvider/interfaces';
import { isMobile } from '../components/GetAppModal';
import { isAppleDevice } from '../components/Donee/components/common/utils';
import { copyToClipboard } from '../utils/clipboard';
import { useEnabledFeatures } from './EnabledFeatures';
import { usePushEvents } from './PushEvents';
import { getLoginTypeLocalStorage } from '../providers/DonorProvider/donorLocalStorage';
import { appDownloadEvent } from './PushEvents/clevertapEvents';

const isApple = isAppleDevice();
const APP_MIGRATE_ENDPOINT = '/online-giving/access/app-migrate';

export const getAppMigrate = (data: string) => {
  const httpRequest = axiosClient.post(
    APP_MIGRATE_ENDPOINT,
    data ? { migrationJsonString: data } : undefined
  );
  return makeApiRequest(httpRequest);
};

export const useDeepLink = (
  eventPayload: object | undefined,
  callback: () => unknown
) => {
  const { pushEvent } = usePushEvents();
  const { isFeatureEnabled } = useEnabledFeatures();
  const [showCopiedModal, setShowCopiedModal] = useState<boolean>(false);
  const [deepLinkRequestState, makeRequest] = useGivingApiRequest<{
    voucher: string;
  }>();

  const isDeferredDeepLinkOn = useCallback(() => {
    return (
      isMobile &&
      window.doneeIdHashed &&
      (isApple
        ? isFeatureEnabled('giving_oga_ddl_autologin_ios')
        : isFeatureEnabled('giving_oga_ddl_autologin_and'))
    );
  }, [isFeatureEnabled]);

  const getDeepLink = useCallback(
    async (
      donee: Donee | undefined | null,
      donor: DonorWithWallet | undefined | null,
      link: string,
      extraData:
        | {
            key: string;
            data: object;
          }
        | undefined
    ) => {
      if (!donee || !donor || !isDeferredDeepLinkOn()) {
        callback();
        return;
      }
      const data = {
        doneeHashedId: window.doneeIdHashed,
        donee,
        donor,
        lastLoginInfo: getLoginTypeLocalStorage()
      };
      const result = extraData
        ? { ...data, [extraData.key]: extraData.data }
        : data;
      const request = getAppMigrate(
        JSON.stringify(toSnakeCase(result, { deep: true }))
      );
      makeRequest(request);
      const response = await request;
      const voucher =
        response?.success && response.data?.response?.payload?.voucher;
      if (!voucher) {
        callback();
        return;
      }
      const copyLink = `${window.location.origin}${link}`;
      eventPayload &&
        pushEvent(appDownloadEvent, {
          ...eventPayload,
          voucher,
          link: copyLink
        });
      copyToClipboard(`${copyLink}?v=${voucher}`);
      if (isApple) {
        setShowCopiedModal(true);
      } else {
        callback();
      }
    },
    [makeRequest, callback, setShowCopiedModal, isDeferredDeepLinkOn]
  );

  return {
    deepLinkRequestState,
    showCopiedModal,
    getDeepLink,
    isDeferredDeepLinkOn
  };
};
